import type ApiService from "@/utils/apiService";
import type { IntAirDrop, TypeAirdropSort, TypeAirdropStatusFilter } from "@m-app";

export const useAirdrop = defineStore("m-app:airdrop", () => {
    const nuxtApp = useNuxtApp();
    const apiService = nuxtApp.$apiService as ApiService;

    const userStore = useUser();

    const airdrops = ref<IntAirDrop[]>([]);
    const airdropsTotalCount = ref(0);
    const airdropsSsrError = ref(false);
    const airdropsLimit = ref(DEFAULT_ITEM_PER_PAGE);
    const airdropsPageIndex = ref(1);
    const airdropsSort = ref<TypeAirdropSort>(null as unknown as TypeAirdropSort);
    const airdropsStatus = ref(null as unknown as TypeAirdropStatusFilter);
    const airdrop = ref<IntAirDrop>({} as IntAirDrop);
    const airdropLoading = ref(false);
    const addAirdropLoading = ref(false);
    const myAirdropsList = ref<string[]>([]);
    const myAirdropWithDetails = ref<IntAirDrop[]>([]);
    const myAirdropWithDetailsTotalCount = ref(0);
    const myAirdropWithDetailsLimit = ref(DEFAULT_ITEM_PER_PAGE);
    const myAirdropWithDetailsPageIndex = ref(1);

    async function getAirdrops({ _limit = airdropsLimit.value, _pageIndex = airdropsPageIndex.value, selectedSort = airdropsSort.value, selectedStatus = airdropsStatus.value } :
            { _limit?: number, _pageIndex?: number, selectedSort?: TypeAirdropSort, selectedStatus?: TypeAirdropStatusFilter } = {}) {

        airdropsPageIndex.value = _pageIndex;
        if(airdropsSort.value !== selectedSort || airdropsStatus.value !== selectedStatus){
            airdropsPageIndex.value = 1;
            airdropsTotalCount.value = 0;
        };
        airdropsSort.value = selectedSort;
        airdropsStatus.value = selectedStatus;

        if (!airdropsTotalCount.value || airdrops.value.length < airdropsTotalCount.value){
            const _querys: Record<string, string> = { limit: String(_limit), page: String(airdropsPageIndex.value) };
            if (airdropsSort.value) _querys.sort = airdropsSort.value;
            if (airdropsStatus.value) _querys.status = airdropsStatus.value;
            try {
                const data = await apiService.send("airdrops", {
                    authHeader: userStore.tgInitDataRaw,
                    querys: _querys,
                });
                airdropsTotalCount.value = data.meta.count;
                if (airdropsPageIndex.value === 1) airdrops.value = data.data;
                else airdrops.value = [...airdrops.value, ...data.data];
                airdropsPageIndex.value += 1;

            } catch (error) {
                if (error instanceof SsrError) airdropsSsrError.value = true;
                console.error("Error getting airdrops:", error);
            }
        }
    }
    async function getAirdropById(id: string) {
        airdropLoading.value = true;
        try {
            const data = await apiService.send("airdrop", {
                uparams: {
                    id,
                },
            });
            airdrop.value = data.data;
        } catch (error) {
            console.error("Error getting airdrop:", error);
        } finally {
            airdropLoading.value = false;
        }
    }
    async function addAirdrop(id: string) {
        addAirdropLoading.value = true;
        try {
            await apiService.send("addAirdrop", {
                authHeader: userStore.tgInitDataRaw,
                data: {
                    id,
                },
            });
        } catch (error) {
            console.error("Error adding airdrop:", error);
        } finally {
            addAirdropLoading.value = false;
        }
    }
    async function getMyAirdrops() {
        try {
            const data = await apiService.send("myAirdrops", {
                authHeader: userStore.tgInitDataRaw,
            });
            myAirdropsList.value = data.data;
        } catch (error) {
            console.error("Error getting my airdrops:", error);
        }
    }
    async function getMyAirdropsDetails() {
        if(!myAirdropWithDetailsTotalCount.value || myAirdropWithDetails.value.length < myAirdropWithDetailsTotalCount.value) {
            const _querys: Record<string, string> = {
                limit: String(myAirdropWithDetailsLimit.value),
                page: String(myAirdropWithDetailsPageIndex.value),
                detailed: "true",
            };
            try {
                const data = await apiService.send("myAirdrops", {
                    authHeader: userStore.tgInitDataRaw,
                    querys: _querys,
                });
                if (myAirdropWithDetailsPageIndex.value === 1) myAirdropWithDetails.value = data.data;
                else myAirdropWithDetails.value = [...myAirdropWithDetails.value, ...data.data];
                myAirdropWithDetailsPageIndex.value += 1;
                if(!myAirdropWithDetailsTotalCount.value) myAirdropWithDetailsTotalCount.value = data.meta.count;
            } catch (error) {
                console.error("Error getting my airdrops details:", error);
            }
        }
    }
    async function visitAridrop(id: string) {
        try {
            await apiService.send("visitAirdrop", {
                authHeader: userStore.tgInitDataRaw,
                data: {
                    id,
                    section: "airdrop",
                },
            });
        } catch (error) {
            console.error("Error visiting airdrop:", error);
        }
    }
    return {
        airdrops,
        airdropsSort,
        airdropsStatus,
        airdropsSsrError,
        airdrop,
        airdropLoading,
        addAirdropLoading,
        myAirdropsList,
        myAirdropWithDetails,
        myAirdropWithDetailsPageIndex,
        // return this to prevent from resetting it in hydration
        airdropsPageIndex,
        getAirdrops,
        getAirdropById,
        addAirdrop,
        getMyAirdrops,
        getMyAirdropsDetails,
        visitAridrop,
    };
});